/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'focus-visible/dist/focus-visible.min';

//region Intl
async function polyfill(locale: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  await import('@formatjs/intl-relativetimeformat/polyfill');
  await import('@formatjs/intl-numberformat/polyfill');

  switch (locale) {
    case 'nl-NL':
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await import('@formatjs/intl-relativetimeformat/dist/locale-data/nl');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await import('@formatjs/intl-numberformat/locale-data/nl');
      break;
    case 'de-DE':
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await import('@formatjs/intl-relativetimeformat/dist/locale-data/de');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await import('@formatjs/intl-numberformat/locale-data/de');
      break;
    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await import('@formatjs/intl-relativetimeformat/dist/locale-data/en');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await import('@formatjs/intl-numberformat/locale-data/en');
      break;
  }
}
void polyfill(navigator.language);
//endregion

// Fixes undefined global object error
// see: https://gitter.im/nrwl-nx/community?at=5f107a99ff29b777320785c1
(window as any).global = window;
